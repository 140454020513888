import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { RichText } from 'prismic-reactjs'

const BlogSection = () => {
  const data = useStaticQuery(graphql`
    query BlogSectionQuery($uid: String) {
      prismic {
        allBlog_posts(uid: $uid, sortBy:date_published_DESC) {
          edges {
            node {
              _meta {
                id
                uid
              }
              title
              date_published
              featured_image
              featured_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      imageArrow: file(relativePath: { eq: "sections/arrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 21) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imagePlaceholder: file(relativePath: { eq: "sections/blog-image-placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const blogPosts = data.prismic.allBlog_posts.edges
  if (!blogPosts) return null

  const imageArrow = data.imageArrow.childImageSharp.fluid
  const imagePlaceholder = data.imagePlaceholder.childImageSharp.fluid

  return (
    <div id='blog' className='bg-white p-4 py-16 sm:py-6 md:py-6'>
      <div className='sm:w-full lg:w-10/12 xl:w-8/12 grid grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-6 mx-auto'>
        {blogPosts.map((blogPost) => {
          return (
            <div className='' key={blogPost.node._meta.id}>
              <div className='border shadow-md'>
                {blogPost.node.featured_image
                  ?
                  <Img fluid={blogPost.node.featured_imageSharp.childImageSharp.fluid} alt='' className='w-full h-64 sm:h-48 object-cover' />
                  :
                  <Img fluid={imagePlaceholder} alt='' className='w-full h-64 sm:h-48 object-cover' />
                }

                <Link to={`/blog/${blogPost.node._meta.uid}`}>
                  <div className='relative hover:bg-grolens-green-400 sm:bg-grolens-green-400 md:bg-grolens-green-400 p-6 h-40'>
                    <h2 className='font-display text-xl text-grolens-green-900 font-extrabold leading-tight mb-3'>
                      {blogPost.node.title
                        ?
                        RichText.asText(blogPost.node.title)
                        :
                        'Blog Title'
                      }
                    </h2>

                    <div className='absolute bottom-0 right-0 flex items-center justify-end mb-6 mr-6'>
                      <p className='font-body text-grolens-green-900 text-sm uppercase font-extrabold tracking-wider hover:underline'>Read</p>
                      <Img fluid={imageArrow} alt='' className='w-5 ml-2' />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default BlogSection